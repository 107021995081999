.module-one {
  display: flex;
  margin: 0;
  margin-bottom: 60px;
  justify-content: center;
  margin-top: 30px;
}

.module-one .img-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-content: flex-end;
}

.module-one .img-wrapper a img {
  width: 500px;
  height: 500px;
  max-width: 500px;
  margin-left: 10%;
  margin-left: 0;
  margin-right: 55px;
}

.module-one .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  margin-top: 30px;
  width: 100%;
}

.module-one .content .module-one-body {
  width: 410px;
  font-size: 20px;
  margin: 20px 0px 25px 10%;
}

.module-one .content .module-one-body-2 {
  font-size: 20px;
  width: 60%;
  margin-left: 10%;
}

.module-one-body-2-strong {
  font-weight: bold;
}

.shop-now .content .module-one-body {
  font-weight: 300;
}

.shop-now .content .module-one-body-2 {
  font-weight: 300;
}

.module-one-cta {
  background-color: black;
  color: white;
  text-decoration: none;
  width: 40%;
  height: 55px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  max-height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-left: 10%;
}

@media only screen and (max-width: 992px) {
  .module-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 25px;
  }

  .module-one .img-wrapper {
    width: 100%;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .module-one .img-wrapper a img {
    margin: 0;
    padding: 0;
    width: 350px;
    height: 350px;
  }

  .module-one .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .module-one .content .module-one-body {
    width: 80%;
    font-size: 20px;
    font-weight: 100;
    line-height: 1.4;
    padding: 0;
    text-align: center;
    margin: 0;
    margin-bottom: 0px;
  }

  .module-one .content .module-one-body-2 {
    width: 80%;
    font-size: 20px;
    font-weight: 100;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .module-one .content .module-one-cta {
    width: 255px;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
    margin-left: 0;
    margin-top: 20px;
  }
}
