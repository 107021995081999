.easy-diy {
  width: 80%;
  margin-left: 10%;
  display: flex;
  margin-bottom: 60px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.easy-diy .img-wrapper {
  display: flex;
  justify-content: flex-end;
}

.easy-diy .img-wrapper img {
  width: 500px;
  height: 500px;
  position: relative;
  right: 50px;
}

.easy-diy .module-3 img {
  max-width: 500px;
  max-height: 500px;
}

.easy-diy .content {
  margin-left: 6%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0%;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
  left: 70px;
}

.easy-diy .content .module-four-head {
  color: black;
  font-size: 20px;
  font-weight: 600;
  max-width: 90%;
  /* margin-bottom: 60px; */
  line-height: 45px;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
  letter-spacing: 2px;
}

/* .easy-diy .content .module-four-head {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: unset;
} */

.easy-diy .content .module-four-sub-head {
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 200;
}

.easy-diy .content .module-four-body {
  color: black;
  /* position: relative; */
  bottom: 5%;
  font-size: 20px;
  line-height: 1.4;
  width: 100%;
  margin-bottom: 30px;
}

.easy-diy .content .module-four-body .kmtg-link {
    color: black;
    font-weight: 300;
}

.easy-diy .content .easy-diy-call-to-action {
  background-color: black;
  color: white;
  width: 40%;
  height: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

@media only screen and (max-width: 992px) {
  .easy-diy {
    display: flex;
    flex-direction: column;
    margin-bottom: 10vw;
  }

  .easy-diy .content .module-four-sub-head {
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    padding: 0 10vw;
  }

  .easy-diy .img-wrapper {
    justify-content: center;
  }

  .easy-diy .img-wrapper img {
    right: 0;
    width: 350px;
    height: 350px;
  }

  .easy-diy .content {
    width: 100%;
  }

  .landing-wrapper .easy-diy .content {
    position: relative;
    right: 0%;
    margin-left: 0%;
    align-items: center;
    justify-content: space-between;
    left: 0;
  }

  .easy-diy .content .module-four-head {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    width: 90%;
    position: relative;
    right: 0%;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }

  .easy-diy .content .module-four-body {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 100;
    line-height: 1.4;
  }

  .easy-diy .content .easy-diy-call-to-action {
    width: 255px;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
  }
}
