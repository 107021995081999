.landing-wrapper .onboarding {
  margin-bottom: 0px;
}

.carousel .control-dots .dot {
  background: #d8d8d8 !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
  background: #606060 !important;
}

.hero-banner-head {
  font-weight: 200;
  line-height: 1.2;
}

.hero .text-wrapper div .hero-banner-body {
  font-weight: 100;
  margin-top: 0px;
}

.hero-banner-call-to-action {
  background-color: #ed0000;
  color: white;
  text-decoration: none;
  border: none;
  font-weight: 500;
  font-size: 20px;
  width: fit-content;
  height: 45px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1440px) {
  .landing-wrapper .hero .text-wrapper {
    padding: 0;
    height: 100%;
    padding-top: 11%;
  }

  .landing-wrapper .hero .text-wrapper h2 {
    line-height: 1.3;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .landing-wrapper .hero {
    height: unset;
  }

  .landing-wrapper .onboarding {
    height: 37vw;
    margin-bottom: 60px;
  }
  .landing-wrapper .onboarding .text-wrapper {
    padding: 7vw 7vw;
  }

  .landing-wrapper .hero-2 .text-wrapper {
    padding: 6vw 4vw;
  }

  .landing-wrapper .hero-2 .text-wrapper h2 {
    width: 450px;
  }

  .landing-wrapper .hero-3 .text-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    justify-content: center;
    padding-left: 4vw;
  }

  .landing-wrapper .hero-3 .text-wrapper .hero-banner-mobile-description {
    display: none;
  }

  .landing-wrapper .hero-2 .text-wrapper .hero-banner-mobile-description {
    display: none;
  }

  .landing-wrapper .hero .text-wrapper h2 {
    line-height: 1.0;
    margin-bottom: 20px;
    font-size: 48px;
  }

}

@media only screen and (max-width: 992px) {
  .landing-wrapper .onboarding .text-wrapper {
    padding: 0 0 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .onboarding .text-wrapper div {
    display: flex;
    justify-content: center;
  }

  .landing-wrapper .hero .text-wrapper h2 {
    margin-bottom: 20px;
  }

  .onboarding .text-wrapper div .hero-banner-body {
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 20px;
  }

  .landing-wrapper .hero-3 .text-wrapper .hero-banner-description {
    display: none;
  }

  .landing-wrapper .hero-2 .text-wrapper .hero-banner-description {
    display: none;
  }

  {
    margin-top: 0px;
  }

  .landing-wrapper -desktop {
    display: none;
  }

  .landing-wrapper .hero .text-wrapper div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 22px;
  }

  .landing-wrapper  {
    font-size: 20px;
    font-weight: 100;
  }

  .hero-banner-call-to-action {
    background-color: #ed0000;
    color: white;
    text-decoration: none;
    border: none;
    padding: 0 0;
    font-weight: 500;
    font-size: 20px;
    width: 255px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
