/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Print Place styles start here. */
html {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    height: 100%;
}

body {
    margin: 0 auto;
    width: 1440px;
    height: 100%;
}

#root {
    height: 100%;
		overflow-x: hidden;
}

@media only screen and (max-width: 1366px){
    body {
        width: 100%;
    }
}

h1 {
    font-size: 24pt;
}

@media only screen and (max-width: 1366px){
    h1 {
        font-size: 14pt;
    }
}

p {
    font-size: 14pt;
    font-weight: 300;
    line-height: 1.4;
}

@media only screen and (max-width: 1366px){
    p {
        font-size: 12pt;
    }
}

.header {
    background-image: url("/images/km_logo_h_b.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex: 0 0 5em;
    margin-bottom: 8px;
    z-index: 2;
    height: 5em;
    font-size:10px;
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 15%), 0 -1px 4px 0 rgb(0 0 0 / 15%);
    width: 100vw;
    left: calc(-50vw + 50%);
}

div.header:lang(de) {
    background-image: url("/images/km_logo_h_b.svg");
}

div.header:lang(override) {
    background-image: url("/images/km_togo_logo_h_b.svg");
}

@media only screen and (max-width: 1366px){
    .header {
        /*background-position: top -11px left 0px;*/
        /*background-size: 1120px;*/
        height: 50px;
    }
}

.para {
    padding: 0 12px 10px 12px;
    letter-spacing: -0.4px;
}

.use-currentloc-on a{
    vertical-align: 18px;
    color: #ff0000;
    text-decoration: underline;
}
.cta {
    font-weight: 600;
    padding: 0 12px 20px 12px;
    letter-spacing: -0.4px;
}

.no-results {
  display: inline;
  padding: 0px 12px;
}

.double-rule {
  border-width: 2px;
  border-style: solid;
  border-color: lightgray;
  margin: 0;
}

.combo-content {
    position: relative;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 992px){
    .holiday-combo-content {
        display: none;
    }
}
@media only screen and (max-width: 1366px){
    .content {
        width: 100%;
    }
}

.combo-hero-image-container {
    background: transparent;
    position: relative;
    min-height: 100%;
}

@media only screen and (max-width: 1366px){
    .hero-image-container {
        background: transparent;
    }
}

@media only screen and (max-width: 1366px){
    .hero-image-container {
        flex-direction: column;
    }
}

.combo-hero-image {
    flex: initial;
    width: 100%;
    min-height: 170px;
    max-height: 500px;
    object-fit: cover;
}

@media only screen and (max-width: 1366px){
    .hero-image {
        flex: initial;
        width: 100%;
    }
}

.combo-hero-image-text {
    Margin: 12px 0px 0px 0px;
}

@media only screen and (max-width: 1366px){
    .hero-image-text {
        padding: 12px 12px 0 33%;
    }
}

@media only screen and (max-width: 1366px){
    .phone-image {
        left: 10px;
        top: 0;
        transform: translateY(107%);
        width: 27%;
    }
}

.combo-footer {
    color: #666666;
    background-color: #f5f5f5;
    padding: 16px 16px 16px 16px;
    flex-shrink: 0;
    margin-top: auto
}

@media only screen and (max-width: 1366px){
    .footer {
        padding: 20px 12px 0 33%;
    }
}

.combo-footer p {
    font-size: 8pt;
    font-weight: 300;
    line-height: 1.4;
}

@media only screen and (max-width: 1366px){
    .footer p {
        font-size: 8pt;
    }
}

.searchbar {
    display: flex;
}

.combo-searchbar {
    display: flex;
    justify-content: space-between;
    border:1px solid rgb(220, 220, 220);
    padding: 2px 12px 2px 12px;
    margin: 0px 12px 10px 12px;
}

*:focus {
    outline: none;
}

.combo-input {
    border: 0;
    flex-grow: 1;
    min-width: 10px; /* added to keep magnifying class icon within search container */
}

input[type="search"]
{
    font-size:100%;
}

.icon-button {
    width: 48px;
    height: 48px;
    border: none;
    padding: initial;
    background: transparent;
}

.busy-small {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.3em solid white;
    border-right: 0.3em solid white;
    border-bottom: 0.3em solid #ff0000;
    border-left: 0.3em solid #ff0000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
  }

  .busy-small, .busy-small:after {
    margin: auto auto;
    border-radius: 50%;
    width: 3em;
    height: 3em;
  }

.chevron-right {
    -webkit-transform: scale(0.6) rotate(45deg);
    -ms-transform: scale(0.6) rotate(45deg);
    transform: scale(0.6) rotate(45deg);
    border-right: 5px solid #ED0000;
    border-top: 5px solid #ED0000;
    box-sizing: border-box;
    height: 1.5em;
    width: 1.5em;
}

.full-screen-loading {
    font-size: 15px;
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-top: 0.3em solid white;
    border-right: 0.3em solid white;
    border-bottom: 0.3em solid #ff0000;
    border-left: 0.3em solid #ff0000;
    border-radius: 50%;


    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
}

.google-privacy-text {
    margin-bottom:8px;
}

.use-currentloc-on {
    display: inline;
    margin-left: 15px;
}

.loc-icon-button {
    width: 48px;
    height: 48px;
    border: none;
    padding: initial;
    background: transparent;
}

.loc-link-button {
    border: none;
    padding: initial;
    background: transparent;
    color: #ff0000;
    text-decoration: underline;
    font: inherit;
    vertical-align: 18px;
}

.machine-entry {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding: 8px 12px 0.5em 12px;
}

.machine-details {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.machine-details p {
    line-height: 1.4;
    font-weight: 600;
    padding-bottom: 0.1em;
    letter-spacing: -0.4px;
}

.store-address {
    padding-bottom: 0.1em;
    letter-spacing: -0.2px;
}

.machine-entry-chev {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}


.formatted-message-edit {
    color: blue;
}

.formatted-message-dev:hover {
    text-decoration: underline overline dotted red;
}

.las-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background: rgba(0,0,0,0.3);
}
.las-modal .las-modal-wrapper {
    margin: auto 6%;
    background-color: white;
    display: flex;
    line-height: 1.25;
    letter-spacing: -0.4px;
    flex-direction: column;
    width: 100%;
}

.las-modal .las-modal-wrapper .las-modal-title {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
}

.las-modal .las-modal-wrapper .las-modal-body {
    /* text-align: center; */
    margin: 0 24px 20px 24px;
    font-size: 16px;
}

.las-modal .las-modal-wrapper button {
    width: 100%;
    border: 0;
    height: 50px;
    background: red;
}

.las-modal .las-modal-wrapper button span {
    color: white;
    font-size: 14px;
    font-weight: 600;
}
