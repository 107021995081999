.one-stop-shop .content .module-three-head {
   font-weight: 300;
   font-size: 45px;
   line-height: 45px;
   padding: 0;
   padding-right: 80px;
   margin-bottom: 35px;
}

.one-stop-shop .content .module-three-body {
   font-weight: 300;
   right: 0%;
   font-size: 20px;
}

.one-stop-shop .content .module-three-call-to-action {
  background-color: black;
  color: white;
  width: 40%;
  height: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.kmtg-link {
    color: black;
    font-weight: 300;
}

@media only screen and (min-width: 992px) {
  .landing-wrapper .one-stop-shop {
    margin-bottom: 60px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: unset;
    padding: 0;
    justify-content: center;
  }

  .landing-wrapper .one-stop-shop .content .module-three-body {
    width: auto;
    padding: 0;
    margin: 0;
    padding: 0 90px 0 0;
    line-height: 1.4;
    padding-left: 15vw;
  }

  .landing-wrapper .one-stop-shop .content .module-three-body-mobile {
    display: none;
  }

  .landing-wrapper .one-stop-shop .content .module-three-call-to-action {
    margin-left: 15vw;
    margin-top: 30px;
  }

  .landing-wrapper .one-stop-shop .content .module-three-head {
    padding-left: 15vw;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: unset;
    letter-spacing: 2px;
  }

  .landing-wrapper .one-stop-shop .content .module-three-sub-head {
    padding-left: 15vw;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .landing-wrapper .one-stop-shop .content {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
  }

  .landing-wrapper .one-stop-shop .img-wrapper {
    width: 100%;
    height: 500px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
  }

  .landing-wrapper .one-stop-shop img {
    width: 500px;
    height: 500px;
    max-height: 500px;
    max-width: 500px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 992px) {
  .one-stop-shop .img-wrapper img {
    width: 350px;
    height: 350px;
    object-fit: contain;
  }

  .landing-wrapper .one-stop-shop {
    padding: 0;
  }

  .one-stop-shop .content {
    width: 100%;
  }

  .one-stop-shop .content .module-three-call-to-action {
    margin: auto;
    width: 255px;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
  }

  .one-stop-shop .content .module-three-head {
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    line-height: 1.2;
    padding-right: 0;
    margin-bottom: 15px;
    letter-spacing: 2px;
  }

  .one-stop-shop .content .module-three-sub-head {
    font-size: 40px;
    margin-bottom: 25px;
    font-weight: 300;
  }

  .one-stop-shop .content .module-three-body {
    display: none;
  }

  .one-stop-shop .content .module-three-body-mobile {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 100;
    width: 89%;
    padding: 0;
    padding-left: 5vw;
  }
}
