.module-two {
  height: 430px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 60px;
  padding: 44px 0px 44px 0px;
}

.module-two .module-two-headline {
  text-align: center;
  font-size: 25px;
  margin-bottom: 40px;
}

.module-two .module-two-headline span {
  font-weight: bold;
  font-size: 22px;
}

.module-two .module-two-headline-mobile span {
  display: none;
}

.module-two .content-wrapper {
  display: flex;
  justify-content: center;
  width: 80%;
}

.module-two .bucket {
  width: 100%;
}

.module-two .bucket p {
  width: 80%;
}

.module-two .bucket a {
  font-size: 20px;
  margin-top: 15px;
  color: black;
  display: flex;
}

.module-two .bucket a svg {
  margin-top: 5px;
}

.module-two .content-wrapper .step-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.module-two .content-wrapper .bucket img {
  object-fit: contain;
  height: 195px;
}

.module-two .content-wrapper .step-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.module-two .content-wrapper .step-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.module-two-image-one-body {
  width: 70%;
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
}

.module-two-image-two-body {
  width: 70%;
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
}

.module-two .bucket .module-two-image-three-body {
  width: 250px;;
}

.module-two-image-three-body {
  width: 70%;
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
}

.how-to-order .module-two-headline {
  margin-bottom: 60px;
}

.how-to-order .content-wrapper .bucket .module-two-image-one-body {
  font-weight: 300;
}

.how-to-order .content-wrapper .bucket .module-two-image-two-body {
  font-weight: 300;
}

.how-to-order .content-wrapper .bucket .module-two-image-three-body {
  font-weight: 300;
}

@media only screen and (max-width: 992px) {
  .module-two {
    height: unset;
    margin-top: 70px;
    margin-bottom: 10vw;
  }

  .module-two .module-two-headline {
    width: 80%;
    display: none;
  }

  .module-two .content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .module-two .module-two-headline-mobile {
    width: 100%;
    height: 100%;
    height: 50px;
    text-align: center;
    margin-bottom: 30px;
  }

  .module-two .module-two-headline-mobile span {
    display: unset;
    font-weight: 700;
    font-size: 25px;
  }


  .module-two .content-wrapper .bucket p {
    width: 90%;
  }

  .module-two .content-wrapper .bucket a {
    margin-bottom: 40px;
  }

  .module-two .content-wrapper .step-3 .module-two-image-three-body {
    width: 82%;
  }
}

@media only screen and (min-width: 1700px) {
  .module-two .bucket p {
    width: 64%;
  }  
}