html {
    font-family: 'Open Sans', sans-serif;
}

img {
    width: 100%;
}

section {
    margin-bottom: 10vw;
}

.landing-wrapper{
    color: #000;
}

.how-to-order {
  height: 530px;
  max-height: 380px;
}
