.social-links {
  width: 30%;
}

.social-links-list {
  display: flex;
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  left: 20%;
}

.social-links ul li svg {
  width: 20px;
}
