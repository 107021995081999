.find {
    background-image: url("/images/KMTG_Mobile_LandingPage-Mod-5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

@media only screen and (min-width: 992px) {
  .mobile-find {
    display: none;
  }
  .find .content .call-to-action {
    text-transform: none;
    padding: 0;
    height: 50px;
    width: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: normal;
    background-color: black;
  }
}

@media only screen and (max-width: 992px) {
  .landing-wrapper .find {
    padding-bottom: 70px;
  }

  .mobile-find {
    display: block;
  }

  .mobile-find .content {
    padding: 15px;
    width: unset;
  }

  .mobile-find .content a {
    display: flex;
    width: fit-content;
    margin: auto;
  }

  .mobile-find .content .call-to-action {
    background-color: black;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 25px;
    text-transform: unset;
  }

  .mobile-find .content h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 30px;
  }

  .mobile-find .content h1 strong {
    font-weight: 600;
  }

  .find {
    display: none;
  }
  .landing-wrapper .find .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .find .content .call-to-action {
    text-transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 255px;
    height: 60px;
    font-size: 23px;
    font-weight: 600;
  }
}
