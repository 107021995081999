html{
    font-family: "Open Sans";
}

img{
    width: 100%;
}

section{
    margin-bottom: 10vw;
}

.landing-wrapper{
    font-size: 2vw;
    color: #000;
}

.call-to-action{
    background-color: #ed0000;
    color: white;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    padding: 3vw 15vw;
    font-weight: 600;
    font-size: 2.25em;
    display: inline-block;
}


.hero img{
    margin-bottom: 10vw;
}

.hero .text-wrapper{
    text-align: center;
}

.hero .text-wrapper h1{
    text-transform: uppercase;
    font-size: 2.25em;
    margin-bottom: 4vw;
    color: #000;
}

.hero .text-wrapper h1 strong{
    font-weight: 600;
}

.hero .text-wrapper h2{
    font-size: 5em;
    margin-bottom: 4vw;
    color: #000;
}

.hero .text-wrapper div{
    margin-bottom: 4vw;
}

.hero .text-wrapper p{
    font-size: 18px;
    font-size: 2.5em;
    font-weight: 400;
}

.hero .text-wrapper p strong{
    font-weight: 600;
    color: #000;
    letter-spacing: 0.5vw;
}

.shop-now{
    text-align: center;
    padding: 0 10vw;
}

.shop-now img{
    margin-bottom: 4vw;
}


.shop-now .content p{
    font-size: 2.25em;
    margin-bottom: 4vw;
    padding: 0 4vw;
}

.shop-now strong{
    font-weight: 600;
    color: #000;
}

.how-to-order{
    background-color: #f1f9fb;
    text-align: center;
    padding: 40px;
    padding: 10vw 15vw;
}

.how-to-order h3{
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 2.25em;
    line-height: 6vw;
    padding: 0 10vw;
    margin-bottom: 8vw;
    color: #000;
}

.how-to-order .bucket{
    margin-bottom: 10vw;
}

.how-to-order .bucket.step-3{
    margin-bottom: 0;
}


.how-to-order .bucket img{
    width: 28vw;
    margin-bottom: 5vw;
}

.how-to-order .bucket p{
    font-size: 2.25em;
    line-height: 6vw;
    padding: 0 5vw;
}

.how-to-order .bucket.step-1 p{
    padding: 0 10vw;
}

.how-to-order .bucket.step-1 p a{
    color: #000;
}

.one-stop-shop{
    padding: 0 10vw;
    text-align: center;
}

.one-stop-shop .img-wrapper{
    padding-bottom: 25px;
}

.one-stop-shop .content h3{
    font-size: 3.75em;
    padding: 0 5vw;
    font-weight: 600;
    margin-bottom: 5vw;
    color: #000;
}

.one-stop-shop .content p{
    font-size: 2.25em;
    padding: 0 9vw;
    margin-bottom: 5vw;
}

.one-stop-shop .call-to-action{
    padding: 3vw 7vw;
}

.find{
    background-image: url("/images/KMTG_Mobile_LandingPage-Mod-5.jpg");
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    text-align: center;
    width: 81%;
    margin: auto;
}

.find h1{
    font-size: 38px;
    line-height: 1.2vw;
    margin-bottom: 5vw;
    color: #000;
    margin-left: auto;
    padding-right: 50px;
    width: 577px;
}

.find h1 strong{
    font-weight: 600;
}

.holiday-village{
    text-align: center;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10vw;
}

.holiday-village img{
    width: 75vw;
}

.holiday-village h3{
    font-size: 4em;
    line-height: 10vw;
    padding: 0 15vw;
    color: #000;
    margin-bottom: 5vw;
}

.holiday-village p{
    font-size: 2.25em;
    padding: 0 10vw;
}

footer{
    background-color: #909090;
    color: #fff;
    padding: 2.5vw 5vw;
}

footer p{
    font-size: 1em;
}

@media only screen and (min-width: 992px) {
    body{
        width: 100vw;
    }

    section{
        margin-bottom: 5vw;
    }

    .call-to-action{
        padding: 1.042vw 4.17vw;
        font-size: 1.39em;
        display: inline-block;
        letter-spacing: 2px;
    }

    .landing-wrapper{
        font-size: 1vw;
    }

    .hero-1{
        background: -webkit-image-set( url("/images/KMTG_LandingPage-Hero-1_@2x.jpg") 2x, url("/images/KMTG_LandingPage-Hero-1.jpg") 1x  );
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        width: 90%;
        height: 41.6vw;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        max-height: 600px;
    }
    .hero-2{
        background: -webkit-image-set( url("/images/KMTG_LandingPage-Hero-2_@2x.jpg") 2x, url("/images/KMTG_LandingPage-Hero-2.jpg") 1x  );
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        width: 90%;
        height: 41.6vw;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        max-height: 600px;
    }
    .hero-3{
        background: -webkit-image-set( url("/images/KMTG_LandingPage-Hero-3_@2x.jpg") 2x, url("/images/KMTG_LandingPage-Hero-3.jpg") 1x  );
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        width: 90%;
        height: 41.6vw;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        max-height: 600px;
    }


    .promo .hero{
        background-image: url("/images/KMTG_LandingPage-HeroPromo-1.jpg");
    }

    .hero img{
        display: none;
    }

    .hero .text-wrapper{
        width: 50%;
        text-align: left;
        padding: 11.39vw 5vw;
    }

    .hero .text-wrapper h1{
        margin-bottom: 2vw;
        font-size: 20px;
        letter-spacing: 2px;
    }

    .hero .text-wrapper h2{
        margin-bottom: 2vw;
        font-size: 4.514em;
    }
    .hero .text-wrapper div{
        margin-bottom: 2vw;
    }

    .hero .text-wrapper p{
        font-size: 25px;
        font-weight: 200;
    }

    .shop-now{
        display: flex;
        box-sizing: border-box;
        margin-bottom: 5vw;
    }

    .shop-now img{
        margin-bottom: 0;
        max-height: 450px;
        max-width: 450px;
    }

    .shop-now .content{
        text-align: left;
        padding: 5vw 0 5vw 2.5vw;
    }

    .shop-now .call-to-action{
        margin-left: 3.472vw;
    }

    .shop-now .content p{
        font-size: 1.94em;
        line-height: 2.64vw;
        margin-bottom: 2vw;
        font-weight: 400;
        padding: 0 3.472vw;
    }

    .img-wrapper, .content{
        flex: 1;
    }

    .how-to-order{
        padding: 3.06vw 0vw;
        padding-bottom: 5vw;
    }

    .how-to-order h3{
        margin-bottom: 2.08vw;
        font-size: 1.77em;
        line-height: 2.5vw;
    }

    .how-to-order .content-wrapper{
        display: flex;
    }

    .how-to-order .content-wrapper .bucket{
        flex: 1;
    }

    .how-to-order .bucket{
        margin-bottom: 0;
    }

    .how-to-order .bucket img{
        width: 14vw;
        margin-bottom: 1vw;
        max-width: 200px;
    }

    .how-to-order .bucket p{
        padding: 0 3vw;
        font-size: 1.95em;
        line-height: 2.639vw;
        font-weight: 400;
    }

    .how-to-order .bucket.step-1 p{
        padding: 0 5vw;
    }

    .how-to-order .bucket.step-3 p{
        padding: 0 4vw;
    }

    .one-stop-shop{
        display: flex;
        text-align: left;
        box-sizing: border-box;
        padding: 0 12.92vw;
    }

    .one-stop-shop div{
        flex: 1;
    }

    .one-stop-shop .img-wrapper{
        order: 2;
        padding-bottom: 0;
        text-align: center;
    }

    .one-stop-shop img{
        max-width: 450px;
        max-height: 450px;
    }

    .one-stop-shop .content{
        order: 1;
        padding-top: 2.99vw;
    }

    .one-stop-shop .content h3{
        padding: 0;
        font-weight: 400;
        padding-right: 7vw;
        margin-bottom: 2vw;
        line-height: 4.5vw;
    }

    .one-stop-shop .content p{
        padding: 0;
        padding-right: 4.5vw;
        margin-bottom: 2.083vw;
        font-size: 1.95em;
        line-height: 2.639vw;
        font-weight: 400;
        width: 100%;
        margin: 0px;
        position: relative;
        right: 13%
    }

    .one-stop-shop .call-to-action {
        padding: 1.042vw 4.17vw;
        font-size: 1.39em;
    }

    .find{
        background: -webkit-image-set( url("/images/KMTG_LandingPage-Mod-5_@2x.jpg") 2x, url("/images/KMTG_LandingPage-Mod-5.jpg") 1x  );
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .find h1{
        font-size: 38px;
        line-height: 1.2;
        margin-bottom: 2vw;
    }

    .find h1 strong{
        display: inline-block;
    }

    .find .content{
        text-align: left;
        padding: 9vw 0vw;
    }

    .holiday-village{
        display: flex;
        padding-bottom: 5vw;

        max-width: 1440px;
        margin: 0 auto;
        margin-bottom: 2vw;
    }

    .holiday-village div{
        flex: 1;
    }

    .holiday-village img{
        width: 37vw;
        max-width: 450px;
        max-height: 450px;
    }

    .holiday-village .content{
        text-align: left;
    }

    .holiday-village .content h3{
        padding: 0;
        margin: 0;
        font-size: 3.75em;
        line-height: 4.44vw;
        padding-right: 15vw;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }

    .holiday-village p{
        font-size: 1.95em;
        line-height: 2.64vw;
        padding: 0;
        padding-right: 15vw;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1440px){
    .call-to-action{
        font-size: 20px;
        padding: 15px 60px;
    }

    .hero{
        margin-bottom: 72px;
        width: 90%;
    }
    .hero .text-wrapper{
        padding: 164px 72px;
    }

    .hero .text-wrapper h1{
        font-size: 20px;
        margin-bottom: 2vw;
    }

    .hero .text-wrapper h2{
        font-size: 48px;
        margin-bottom: 20px;
    }

    .hero .text-wrapper div{
        margin-bottom: 28.8px;
    }

    .hero .text-wrapper p{
        font-size: 25px;
        font-weight: 200;
    }

    .shop-now{
        max-width: 1440px;
        margin: 0 auto;
        margin-bottom: 72px;
        padding: 0 144px;
    }

    .shop-now .img-wrapper, .shop-now .content{
        max-width: 720px;
    }

    .shop-now .content{
        padding: 72px 0px 72px 36px;
    }

    .shop-now .content p{
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 28.8px;
        padding: 0 50px;
    }

    .shop-now .call-to-action{
        margin-left: 50px;
    }

    .how-to-order{
        padding: 44px 0;
        max-width: 1440px;
        margin: 0 auto;
        margin-bottom: 72px;
    }

    .how-to-order h3{
        font-size: 25px;
        margin-bottom: 28.8px;
        letter-spacing: 2px;
        line-height: 36px;
        padding: 0;
    }

    .how-to-order .how-to-order-title {
      font-size: 30px;
      letter-spacing: 2px;
      line-height: 36px;
      padding: 0;
      margin-bottom: 50px;
    }

    .how-to-order .bucket img{
        margin-bottom: 14.4px;
    }

    .how-to-order .bucket p{
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 30px;
        padding: 0 45px;
    }

    .how-to-order .bucket.step-1 p{
        padding: 0 67px;
    }

    .how-to-order .bucket.step-3 p{
        padding: 0 37px;
    }

    .one-stop-shop{
        max-width: 1440px;
        margin: 0 auto;
        margin-bottom: 72px;
        padding: 0 186px;
    }

    .one-stop-shop .content{
        padding-top: 43px;
    }

    .one-stop-shop .content h3{
        font-size: 54px;
        margin-bottom: 28.8px;
        line-height: 64.8px;
        padding-right: 0px;
    }

    .one-stop-shop .call-to-action {
        font-size: 20px;
        padding: 15px 60px;
    }

    .one-stop-shop .content p{
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 30px;
        padding-right: 40px;
    }

    .one-stop-shop img{
        height: 450px;
        width: 450px;
    }

    .find{
        margin-bottom: 72px;
        max-height: 462px;
    }

    .find h1{
        font-size: 38px;
        line-height: 1.2;
        margin-bottom: 28.8px;
    }

    .find .content{
        padding: 130px 0;
    }

    .holiday-village{
        padding-bottom: 72px;
    }

    .holiday-village .content h3{
        font-size: 54px;
        line-height: 64px;
        margin-bottom: 28.8px;
        margin-top: 28.8px;
        padding-right: 216px;
    }

    .holiday-village p{
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 28.8px;
        padding-right: 180px;
    }
}

@media only screen and (min-width: 1700px) {
    .find .content{
        padding: 130px 0;
        width: 86%;
    }
}
