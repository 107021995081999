.kmtg-holiday-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666666;
  border-top: 1px solid grey;
}

.kmtg-holiday-footer .kmtg-holiday-footer-title {
  background-image: url("/images/KodakMoments_logo.svg");
  background-repeat: no-repeat;
  width: 400px;
  height: 70px;
  overflow-y:auto;
  margin: 20px 0 20px 5%;
}

.kmtg-holiday-footer ul {
  color: #666666;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  margin-bottom: 30px;
}

.kmtg-holiday-footer ul a {
  text-decoration: none;
  color: #666;
}

.kmtg-holiday-footer-bottom {
  display: flex;
  justify-content: space-between;
}

.hmtg-holiday-footer-bottom-mobile {
  display: none;
}


@media only screen and (max-width: 992px) {
  .kmtg-holiday-footer {
    display: none;
  }

  .kmtg-holiday-footer-bottom {
    display: none;
  }
  .hmtg-holiday-footer-bottom-mobile {
    display: flex;
  }
}
